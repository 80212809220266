import { useMemo } from 'react'

import { optionValues } from '../components/ArticleTimePeriodSelection/constants'
import { getTimestampInSeconds } from '../components/ArticleTimePeriodSelection/utilities'
import { Params } from '../generated-types/types.schemas'
import formatStringToObject from '../utilities/formatStringToObject'

import { enableGroupingSettingName } from '../constants/settings'
import useBaskets from './useBaskets'
import useSettingValue from './useSettingValue'

const defaultParams: Params = {
  requestedarticles: 20,
  icon_resolution: 49,
  main: {
    header: 2,
    summary: 2,
    text: 2,
    quotes: 2,
    colorbar: true,
    matches: true,
    shortsummarylength: 1932,
    shortbodylength: -1932,
  },
  identical: { inherit: true },
  colorbaropt: { pixels: 0, max_color: 8 },
  groupidentical: true,
  use_short_links: true,
  picture_types: '2,4',
  include_equalgroup_id: 1,
  mark_matches_url: 1,
  sort_oldest_first: false,
  watch_id: -1,
  different_colors: 8,
  allsubject: '0',
  allmeta: true,

  // Setting persistent to "false" forces a fresh query to the index. This resolves the issue with incorrect article counts in "Added" and "Removed" categories.
  // Read more: https://forum.infomedia.dk/t/without-making-any-changes-to-the-query-results-are-shown-in-added/3229/6
  // @ts-expect-error: Param is missing from searchd documentation.
  persistent: false,

  newest: getTimestampInSeconds(optionValues['30 days'].startDate as number),
  oldest: getTimestampInSeconds(optionValues['30 days'].endDate as number),
}

const useSearchParams = (): Params | undefined => {
  const { value: articlesMetaData, isLoading } = useSettingValue<string>(
    'newPortalShowMetadata',
    true,
  )
  // Custom QB grouping parameter
  const { value: enableGroupingValue } = useSettingValue<boolean>(
    enableGroupingSettingName,
    true,
  )

  const baskets = useBaskets()

  return useMemo(() => {
    if (isLoading || !baskets) {
      return undefined
    }

    const articlesMetaDataValues: boolean =
      !!articlesMetaData &&
      Object.values(formatStringToObject(articlesMetaData)).filter(
        (value) => value,
      ).length > 0

    return {
      ...defaultParams,
      groupidentical: enableGroupingValue,
      identical: {
        inherit: enableGroupingValue,
      },
      readership: articlesMetaDataValues,
      baskets,
    }
  }, [enableGroupingValue, articlesMetaData, baskets, isLoading])
}

export default useSearchParams
