import {
  Clock16Filled,
  Play20Filled,
  Play24Filled,
} from '@fluentui/react-icons'
import { useRef, useState } from 'react'
import {
  Article,
  Button,
  HighlightedMatchesAndEntities,
} from '@opoint/infomedia-storybook'
import { removeMatchTagsWithEntAndColorZeroAttribute } from '../ArticleLists/utilities'

const convertSecondsToHMSFormat = (seconds: number) => {
  const date = new Date(seconds * 1000).toISOString()

  // If the video is less than 1 hour, we only want to show minutes and seconds
  if (seconds < 3600) {
    return date.slice(14, 19)
  }

  return date.slice(11, 19)
}

const BASE_VIDEO_URL = 'https://m360.opoint.com'

const Media = ({
  article: { articlemedia, orig_url, first_source, matches: articleMatches },
}: {
  article: Article
}) => {
  const matches = articleMatches?.filter((item) => {
    if (!item?.quote?.text) {
      return false
    }

    const match = removeMatchTagsWithEntAndColorZeroAttribute(item.quote.text)
    return match.includes('<match')
  })

  const hasMatches = matches && matches.length > 0

  const videoRef = useRef<HTMLVideoElement>(null)

  const handlePlayAtSpecificTime = async (seconds: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = seconds
      videoRef.current.paused && (await videoRef.current.play())
    }
  }

  const [showFullTranscript, setShowFullTranscript] = useState(false)

  const videoUrl = articlemedia?.[0]?.localurl || articlemedia?.[0]?.url

  const fullTranscript = (articlemedia && articlemedia[0]?.text) || ''

  return (
    <div>
      {videoUrl ? (
        <video
          className="w-full bg-black"
          preload="metadata"
          controls
          ref={videoRef}
        >
          <source src={`${BASE_VIDEO_URL}${videoUrl}`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div className="flex h-48 w-full flex-col items-center justify-center bg-sky.9">
          <a href={orig_url} target="_blank" rel="noopener noreferrer">
            <div className="mb-2 flex size-10 items-center justify-center rounded-full bg-sky.1 text-white hover:bg-sand.3">
              <Play24Filled />
            </div>
          </a>
          <span className="text-sm text-grey.4">{`Play the media at ${first_source.name}`}</span>
        </div>
      )}
      {hasMatches && (
        <div className="mt-3 w-full bg-sky.9 p-3">
          <p className="text-sm font-bold">
            {matches.length === 1
              ? 'Your searchword was found 1 time in the video.'
              : `Your searchword was found ${matches.length} times in the video.`}
          </p>
          <p className="mt-1 text-xs">
            To be able to search in the video, our computers have transformed
            the video into text
          </p>
          <div className="mt-2">
            {matches.map(({ text, play_offset, quote }, index) => (
              <div key={`${text}${index}`}>
                <div className="mt-4 flex flex-row items-center justify-between">
                  <div>
                    <p className="text-sm font-bold">{text}</p>
                    {play_offset && (
                      <div className="my-2 flex flex-row items-center">
                        <Clock16Filled />
                        <span className="ms-2 text-sm font-bold">
                          {convertSecondsToHMSFormat(play_offset)}
                        </span>
                      </div>
                    )}
                  </div>
                  {videoUrl && (
                    <Button
                      onClick={() => {
                        if (play_offset) {
                          void handlePlayAtSpecificTime(play_offset)
                        }
                      }}
                      size="icon"
                      type="button"
                      className="mb-2 flex size-8 items-center justify-center rounded-full bg-sky.1 text-white hover:bg-sand.3"
                    >
                      <Play20Filled />
                    </Button>
                  )}
                </div>
                {quote && (
                  <p className="text-sm text-sky.cloudy">
                    (...) <HighlightedMatchesAndEntities text={quote.text} />{' '}
                    (...)
                  </p>
                )}
                {index !== matches.length - 1 && (
                  <hr className="my-3 text-sky.7" />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {fullTranscript && (
        <div className="text-center">
          <Button
            variant="text"
            type="button"
            onClick={() => {
              setShowFullTranscript(!showFullTranscript)
            }}
          >
            {showFullTranscript ? 'Hide' : 'Show'} full transcript
          </Button>
          {showFullTranscript && (
            <div className="text-left text-sm">
              <HighlightedMatchesAndEntities text={fullTranscript} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Media
