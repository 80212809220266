import { useCallback, useState } from 'react'
import {
  Article,
  ArticleItem as Item,
  ArticleItemActiveSource,
  ArticleItemActiveSourceDate,
  ArticleItemContent,
  ArticleItemSourceRow,
  cn,
  getArticleId,
  getSimilarArticles,
  hasSimilarArticles,
} from '@opoint/infomedia-storybook'
import CompareStateBadge from './CompareStateBadge'
import { type TabType } from './Tabs'
import { ArticleItemTitle } from './ArticleItemTitle'
import { ArticleItemExcerpt } from './ArticleItemExcerpt'

interface ArticleItemProps {
  article: Article
  onClick: (article: Article) => void
  badgeType: TabType
  activeArticleId: string | null
}

/**
 * Function copies input article to 1st position in
 * identical_documents.document array but without identical_documents prop
 * @param {Article} article - article to process
 * @return Article
 */
const createModifiedArticle = (article: Article) => {
  if (!article.identical_documents || article.identical_documents.cnt === 0) {
    return article
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { identical_documents, ...articleWithoutIdenticalDocuments } = article

  return {
    ...article,
    identical_documents: {
      ...article.identical_documents,
      document: [
        articleWithoutIdenticalDocuments,
        ...(article.identical_documents.document || []),
      ],
    },
  } as Article
}

export const ArticleItem = ({
  article,
  onClick,
  badgeType,
  activeArticleId,
}: ArticleItemProps) => {
  const hasSimilar = hasSimilarArticles(article)
  const modifiedArticle = hasSimilar ? createModifiedArticle(article) : article
  const identicalArticles = getSimilarArticles(modifiedArticle)
  const isActive = identicalArticles.some(
    (ia) => getArticleId(ia) === activeArticleId,
  )
  const [activeArticleSourceId, setActiveArticleSourceId] = useState<string>(
    getArticleId(modifiedArticle),
  )

  const handleSetActiveArticleSourceId = useCallback(
    (articleId: string): void => {
      // First set the new active article ID
      setActiveArticleSourceId(articleId)

      // Then find and trigger onClick with the newly selected article
      const selectedArticle = identicalArticles.find(
        (article) => getArticleId(article) === articleId,
      )

      if (selectedArticle) {
        onClick(selectedArticle)
      }
    },
    [identicalArticles, onClick],
  )

  const handleItemClick = useCallback((): void => {
    if (!hasSimilar) {
      onClick(article)
    }

    const activeArticle = identicalArticles.find(
      (article) => getArticleId(article) === activeArticleSourceId,
    )

    if (activeArticle) {
      onClick(activeArticle)
    }
  }, [activeArticleSourceId, article, hasSimilar, identicalArticles, onClick])

  return (
    <Item
      article={modifiedArticle}
      activeArticleSourceId={activeArticleSourceId}
      onClick={handleItemClick}
      onSetArticleSource={handleSetActiveArticleSourceId}
      className={cn(
        'relative border-b border-b-grey.6 transition-colors hover:bg-sky.9',
        isActive && 'bg-sky.8 hover:bg-sky.8',
      )}
    >
      <ArticleItemContent>
        <div className="px-2">
          <CompareStateBadge type={badgeType} />
          <ArticleItemSourceRow>
            <ArticleItemActiveSourceDate />
            <ArticleItemActiveSource />
          </ArticleItemSourceRow>
          <ArticleItemTitle />
          <ArticleItemExcerpt />
        </div>
      </ArticleItemContent>
    </Item>
  )
}
