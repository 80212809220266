/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  Article,
  cn,
  Dialog,
  DialogBody,
  DialogContent,
  getArticleId,
  Loader,
} from '@opoint/infomedia-storybook'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useGlueSearch, useSearch } from '../../generated-types/search/search'
import {
  Params,
  ProfileDetail,
  SearchError,
  SearchGlueRequestBody,
  SearchRequestBodyExpressionsSearchlineObjectExpressionsItem,
  SearchResponse,
} from '../../generated-types/types.schemas'
import useSearchParams from '../../hooks/useSearchParams'

import ActiveArticle from '../ActiveArticle'
import ArticleListPlaceholder from '../ArticleListPlaceholder'
import ArticleLists, { DocumentWithBadgeType } from '../ArticleLists'

import {
  OptionLabel,
  optionValues,
} from '../ArticleTimePeriodSelection/constants'

import { TabType } from '../ArticleLists/Tabs'
import { TimePeriodOption } from '../ArticleTimePeriodSelection/types'
import { getTimestampInSeconds } from '../ArticleTimePeriodSelection/utilities'
import { IdenticalArticlesProvider } from '../../context/identicalArticlesContext'
import useProfileSearchImpact from '../../hooks/useProfileSearchImpact'
import ActionBar from './ActionBar'

const getCompareRequestData = (
  searchParams: (Params & { persistent?: number }) | undefined,
  expressions_one:
    | SearchRequestBodyExpressionsSearchlineObjectExpressionsItem[]
    | undefined,
  expressions_two:
    | SearchRequestBodyExpressionsSearchlineObjectExpressionsItem[]
    | undefined,
): SearchGlueRequestBody => ({
  params: searchParams || {},
  items: [
    {
      list_two: {
        expressions: expressions_one || [],
      },
      list_one: {
        expressions: expressions_two || [],
      },
      glue_start: '(',
      glue_middle: ') AND NOT (',
      glue_end: ')',
    },
  ],
})

const getWindowWidth = () => {
  return window.innerWidth
}

const XXL_BREAKPOINT = 1536 // 2xl tailwind breakpoint

type Props = {
  sourceExpressions?: SearchRequestBodyExpressionsSearchlineObjectExpressionsItem[]
  targetExpressions?: SearchRequestBodyExpressionsSearchlineObjectExpressionsItem[]
  profileData?: ProfileDetail
}

const ArticlesPreview = ({
  sourceExpressions,
  targetExpressions,
  profileData,
}: Props) => {
  const searchParams = useSearchParams()
  const [windowWidth, setWindowWidth] = useState<number>(getWindowWidth())
  const isBelowXxl = windowWidth < XXL_BREAKPOINT

  const { shouldWarnUser, shouldBlockSearch } =
    useProfileSearchImpact(profileData)

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(getWindowWidth())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const [timePeriod, setTimePeriod] = useState<TimePeriodOption>({
    label: '30 days',
    ...optionValues['30 days' as OptionLabel],
  })

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false)

  const [resultsArticles, setResultsArticles] = useState<
    DocumentWithBadgeType[]
  >([])
  const [resultsArticlesError, setResultsArticlesError] = useState<string>('')
  const [resultsArticlesCount, setResultsArticlesCount] = useState<number>(0)

  const [addedArticles, setAddedArticles] = useState<DocumentWithBadgeType[]>(
    [],
  )
  const [addedArticlesError, setAddedArticlesError] = useState<string>('')
  const [addedArticlesCount, setAddedArticlesCount] = useState<number>(0)

  const [removedArticles, setRemovedArticles] = useState<
    DocumentWithBadgeType[]
  >([])
  const [removedArticlesError, setRemovedArticlesError] = useState<string>('')
  const [removedArticlesCount, setRemovedArticlesCount] = useState<number>(0)

  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(false)
  const [hasInitialFetfched, setHasInitialFetched] = useState<boolean>(false)

  const addedArticlesContext = useRef<string | null>(null)
  const removedArticlesContext = useRef<string | null>(null)
  const resultsArticlesContext = useRef<string | null>(null)

  const [activeArticle, setActiveArticle] = useState<Article | undefined>()

  const handleSetActiveArticle = (article: Article) => {
    setActiveArticle(article)
    setIsPreviewModalOpen(true)
  }

  const {
    mutateAsync: resultsArticlesMutate,
    isLoading: isLoadingResultsArticles,
  } = useSearch()

  const {
    mutateAsync: addedArticlesMutate,
    isLoading: isLoadingAddedArticles,
  } = useGlueSearch()

  const {
    mutateAsync: removedArticlesMutate,
    isLoading: isLoadingRemovedArticles,
  } = useGlueSearch()

  const setArticleListData = (
    type: TabType,
    data: SearchResponse | SearchError,
    isFetchMore: boolean,
  ) => {
    const newContext = (data as SearchResponse).context
    const errors = (data as SearchError).errors
    // Add badge type to the articles
    const articles = ((data as SearchResponse).document || []).map((doc) => ({
      ...doc,
      badgeType: type,
    }))

    const setArticles = (prev: DocumentWithBadgeType[]) => {
      // Append data only on fetchMore
      if (isFetchMore) {
        return [...prev, ...articles]
      }

      return articles
    }

    const setCount = (prev) => {
      // Set count only from the first batch
      if (prev === 0) {
        return (data as SearchResponse).range_count || 0
      }

      return prev
    }

    switch (type) {
      case 'added':
        addedArticlesContext.current = newContext
        setAddedArticlesError(errors)
        setAddedArticlesCount(setCount)
        setAddedArticles((prev) => setArticles(prev) as DocumentWithBadgeType[])
        break
      case 'removed':
        removedArticlesContext.current = newContext
        setRemovedArticlesError(errors)
        setRemovedArticlesCount(setCount)
        setRemovedArticles(
          (prev) => setArticles(prev) as DocumentWithBadgeType[],
        )
        break
      case 'results':
        resultsArticlesContext.current = newContext
        setResultsArticlesError(errors)
        setResultsArticlesCount(setCount)
        setResultsArticles(
          (prev) => setArticles(prev) as DocumentWithBadgeType[],
        )
        break
    }
  }

  const handleFetchArticles = useCallback(
    async ({
      newTimePeriod,
      setFirstArticleAsActive = false,
      isFetchMore,
    }: {
      newTimePeriod?: TimePeriodOption
      setFirstArticleAsActive?: boolean
      isFetchMore: boolean
    }) => {
      if (!sourceExpressions || !searchParams) {
        return
      }

      const timePeriodParams = {
        oldest: getTimestampInSeconds(
          newTimePeriod?.startDate ?? timePeriod.startDate ?? 0,
        ),
        newest: getTimestampInSeconds(
          newTimePeriod?.endDate ?? timePeriod.endDate ?? 0,
        ),
      }

      const commonParams = {
        ...searchParams,
        ...timePeriodParams,
      }

      const addedArticlesRequest =
        // Don't fetch more when context is empty or when we don't compare queries
        (isFetchMore && addedArticlesContext.current === '') ||
        !targetExpressions
          ? Promise.resolve(null)
          : addedArticlesMutate({
              data: getCompareRequestData(
                {
                  ...commonParams,
                  context:
                    (isFetchMore ? addedArticlesContext.current : '') || '',
                },
                targetExpressions,
                sourceExpressions,
              ),
            })

      const removedArticlesRequest =
        // Don't fetch more when context is empty or when we don't compare queries
        (isFetchMore && removedArticlesContext.current === '') ||
        !targetExpressions
          ? Promise.resolve(null)
          : removedArticlesMutate({
              data: getCompareRequestData(
                {
                  ...commonParams,
                  context:
                    (isFetchMore ? removedArticlesContext.current : '') || '',
                },
                sourceExpressions,
                targetExpressions,
              ),
            })

      const resultsArticlesRequest =
        // Don't fetch more when context is empty
        isFetchMore && resultsArticlesContext.current === ''
          ? Promise.resolve(null)
          : resultsArticlesMutate({
              data: {
                expressions: sourceExpressions,
                params: {
                  ...commonParams,
                  context:
                    (isFetchMore ? resultsArticlesContext.current : '') || '',
                },
              },
            })

      const [added, removed, results] = await Promise.all([
        addedArticlesRequest,
        removedArticlesRequest,
        resultsArticlesRequest,
      ])

      setIsInitialLoading(false)
      setHasInitialFetched(true)

      if (added !== null) {
        setArticleListData('added', added.searchresult, isFetchMore)
      }

      if (removed !== null) {
        setArticleListData('removed', removed.searchresult, isFetchMore)
      }

      if (results !== null) {
        setArticleListData('results', results.searchresult, isFetchMore)

        if (setFirstArticleAsActive) {
          setActiveArticle(
            (results.searchresult as SearchResponse).document?.[0] as Article,
          )
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We don't want to refetch articles when the time period changes
    [
      addedArticlesMutate,
      removedArticlesMutate,
      resultsArticlesMutate,
      searchParams,
      sourceExpressions,
      targetExpressions,
    ],
  )

  const resetArticles = () => {
    setResultsArticles([])
    setAddedArticles([])
    setRemovedArticles([])
    setResultsArticlesCount(0)
    setAddedArticlesCount(0)
    setRemovedArticlesCount(0)
    addedArticlesContext.current = null
    removedArticlesContext.current = null
    resultsArticlesContext.current = null
    setIsInitialLoading(true)
  }

  useEffect(() => {
    // Don't fetch articles when impact number is too high
    if (shouldBlockSearch) {
      return
    }

    resetArticles()

    void handleFetchArticles({
      setFirstArticleAsActive: true,
      isFetchMore: false,
    })
  }, [handleFetchArticles, shouldBlockSearch])

  const handleTimePeriodChange = (newTimePeriod: TimePeriodOption) => {
    setTimePeriod(newTimePeriod)
  }

  if (!hasInitialFetfched && !isInitialLoading) {
    return (
      <div className="flex h-full items-center justify-center border-l">
        {shouldBlockSearch ? (
          <p>
            This query is too heavy to perform on the system currently and has
            been blocked.
          </p>
        ) : (
          <ArticleListPlaceholder text="Click 'Preview' to see the results" />
        )}
      </div>
    )
  }

  if (isInitialLoading) {
    return (
      <div className="flex size-full flex-col items-center justify-center">
        {shouldWarnUser && (
          <p className="mb-4">
            This query has a large impact on the system and could take a bit
            longer than usual to perform.
          </p>
        )}
        <Loader />
      </div>
    )
  }

  return (
    <IdenticalArticlesProvider>
      <div className="size-full">
        <div className="grid size-full grid-cols-1 2xl:grid-cols-2">
          <div className="h-full">
            <ActionBar
              activeTimePeriod={timePeriod}
              isArticlesLoading={isInitialLoading}
              onActiveTimePeriodChange={handleTimePeriodChange}
            />
            <div className="h-auto md:h-[calc(100vh-240px)]">
              <ArticleLists
                activeArticleId={
                  activeArticle ? getArticleId(activeArticle) : null
                }
                addedArticles={{
                  data: addedArticles,
                  loading: isLoadingAddedArticles,
                  fetchMore: () => handleFetchArticles({ isFetchMore: true }),
                  count: addedArticlesCount,
                  error: addedArticlesError,
                }}
                isCompareEnabled={!!targetExpressions}
                onActiveArticleChange={handleSetActiveArticle}
                removedArticles={{
                  data: removedArticles,
                  loading: isLoadingRemovedArticles,
                  fetchMore: () => handleFetchArticles({ isFetchMore: true }),
                  count: removedArticlesCount,
                  error: removedArticlesError,
                }}
                resultsArticles={{
                  data: resultsArticles,
                  loading: isLoadingResultsArticles,
                  fetchMore: () => handleFetchArticles({ isFetchMore: true }),
                  count: resultsArticlesCount,
                  error: resultsArticlesError,
                }}
              />
            </div>
          </div>
          {activeArticle && (
            <div
              className={cn(
                'hidden 2xl:block overflow-y-auto px-4 py-8 border-l border-grey.6 h-[calc(100vh-117px)]',
              )}
              key={getArticleId(activeArticle)}
            >
              <ActiveArticle article={activeArticle} />
            </div>
          )}

          {isBelowXxl && (
            <Dialog
              onOpenChange={() => {
                setIsPreviewModalOpen(false)
              }}
              open={isPreviewModalOpen}
            >
              <DialogContent>
                <DialogBody className="max-h-[85vh] overflow-y-auto">
                  <ActiveArticle article={activeArticle} />
                </DialogBody>
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
    </IdenticalArticlesProvider>
  )
}

export default ArticlesPreview
