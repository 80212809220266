import { RiInstagramFill, RiTwitterXFill, RiMetaFill } from 'react-icons/ri'
import { Article, ArticleSocialMediaType } from '@opoint/infomedia-storybook'

export const isArticleBroadcastType = (article: Article): boolean =>
  ['TV', 'WEB_TV', 'RADIO', 'WEB_RADIO'].includes(article.mediatype?.text || '')

export enum SocialMedia {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Twitter = 'Twitter',
}

export const getSocialMediaIcon = (socialMedia: SocialMedia) => {
  if (socialMedia === SocialMedia.Facebook) {
    return <RiMetaFill fontSize={25} />
  }

  if (socialMedia === SocialMedia.Instagram) {
    return <RiInstagramFill size={25} />
  }

  if (socialMedia === SocialMedia.Twitter) {
    return <RiTwitterXFill size={25} />
  }

  return null
}

export const getSocialMediaType = (
  sitename: string,
): ArticleSocialMediaType => {
  if (sitename === 'Twitter') {
    return 'twitter'
  }
  if (sitename === 'Instagram') {
    return 'instagram'
  }
  return 'facebook'
}
