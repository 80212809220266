import {
  cn,
  HighlightedMatchesAndEntities,
  useArticleItemContext,
} from '@opoint/infomedia-storybook'
import { forwardRef, HTMLProps } from 'react'

export const ArticleItemTitle = forwardRef<
  HTMLHeadingElement,
  HTMLProps<HTMLHeadingElement>
>(({ className, ...props }, ref) => {
  const { activeArticle } = useArticleItemContext()

  return (
    <h3
      className={cn('mt-3 font-slab text-body', className)}
      ref={ref}
      {...props}
    >
      <HighlightedMatchesAndEntities text={activeArticle.header?.text} />
    </h3>
  )
})

ArticleItemTitle.displayName = 'ArticleItemTitle'

export default ArticleItemTitle
