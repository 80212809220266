import {
  ArticlePreviewImage,
  HighlightedMatchesAndEntities,
} from '@opoint/infomedia-storybook'
import { DocumentArticleimagesArticleimageItem } from '../../generated-types/types.schemas'

const ImageWithCaption = ({
  image,
}: {
  image: DocumentArticleimagesArticleimageItem
}) => (
  <>
    <ArticlePreviewImage imageUrl={image.url || ''} />
    <div className="my-5 text-sky.cloudy">
      <HighlightedMatchesAndEntities text={image.caption?.text} />
    </div>
  </>
)

export default ImageWithCaption
