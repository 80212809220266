import {
  Article,
  ArticlePreviewAuthor,
  ArticlePreviewBody,
  ArticlePreviewExcerpt,
  ArticlePreviewImage,
  ArticlePreviewTitle,
  HighlightedMatchesAndEntities,
  getArticleId,
} from '@opoint/infomedia-storybook'
import { List16Regular } from '@fluentui/react-icons'
import ImageWithCaption from './ImageWithCaption'
import Media from './Media'
import { getScreenshotThumbnail } from './helpers'

const RegularPreview = ({ article }: { article: Article }) => {
  const {
    articleimages,
    mediatype,
    header,
    caption,
    summary,
    body,
    id_article,
    id_site,
    author,
    articlemedia,
    screenshots,
    pdf_file,
  } = article

  const firstImage = articleimages?.articleimage[0]
  const restOfImages = articleimages?.articleimage.slice(1)
  const haveRestOfImages = restOfImages && restOfImages.length > 0
  const havePdf = pdf_file !== undefined
  const screenshotThumbnail = getScreenshotThumbnail(screenshots)

  const isBroadcastType = ['TV', 'WEB_TV', 'RADIO', 'WEB_RADIO'].includes(
    mediatype?.text || '',
  )

  const hasBody = body?.text && body.text !== '<p></p>'
  const articleId = `id:${getArticleId(article)}`

  return (
    <>
      {article.word_count && (
        <p className="m-0 my-3 flex items-center text-sky.cloudy">
          <List16Regular />
          <span className="ms-1 text-sm">{article.word_count}</span>
        </p>
      )}
      {!!author && <ArticlePreviewAuthor>{author}</ArticlePreviewAuthor>}
      <ArticlePreviewTitle>
        {article.orig_url ? (
          <a
            href={article.orig_url}
            rel="noreferrer"
            target="_blank"
            className="hover:text-sky.cloudy"
          >
            <h1>
              <HighlightedMatchesAndEntities text={header?.text} />
            </h1>
          </a>
        ) : (
          <h1>
            <HighlightedMatchesAndEntities text={header?.text} />
          </h1>
        )}
      </ArticlePreviewTitle>
      <ArticlePreviewExcerpt>
        <HighlightedMatchesAndEntities text={summary?.text} />
      </ArticlePreviewExcerpt>
      {havePdf && screenshotThumbnail && (
        <>
          <a href={article.orig_url} rel="noreferrer" target="_blank">
            <ArticlePreviewImage
              imageUrl={screenshotThumbnail.text}
              className="w-60"
            />
          </a>
          <div className="my-5 text-sky.cloudy">
            <HighlightedMatchesAndEntities text={caption?.text} />
          </div>
        </>
      )}
      {articlemedia || isBroadcastType ? (
        <Media article={article} />
      ) : firstImage ? (
        <ImageWithCaption image={firstImage} />
      ) : null}
      {hasBody ? (
        <>
          <ArticlePreviewBody
            articleId={id_article}
            siteId={id_site}
            useHighlightMatching
            keepOpen
            bodyText={body?.text || ''}
          />
        </>
      ) : (
        <div className="text-xs text-grey.4">{articleId}</div>
      )}
      {haveRestOfImages &&
        restOfImages.map((image, index) => (
          <ImageWithCaption image={image} key={`caption-${index}`} />
        ))}
    </>
  )
}

export default RegularPreview
