import {
  useArticleItemContext,
  getArticleSocialSource,
  cn,
  HighlightedMatchesAndEntities,
  SocialSource,
} from '@opoint/infomedia-storybook'

import { HTMLAttributes, forwardRef } from 'react'
import { keepTextAroundMatches } from './utilities'

type ArticleItemExcerptProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'>

const ArticleItemExcerpt = forwardRef<HTMLDivElement, ArticleItemExcerptProps>(
  ({ className, ...props }, ref) => {
    const { activeArticle } = useArticleItemContext()

    const { summary, articlemedia, caption, body } = activeArticle

    // Process the summary text based on social media metadata
    const excerpt = keepTextAroundMatches({
      text: `${summary?.text} ${caption?.text} ${body?.text} ${articlemedia?.[0]?.text}`,
    })

    // Get the social media source of the article
    const socialSource = getArticleSocialSource(activeArticle.first_source.id)

    return (
      <div
        ref={ref}
        className={cn('mt-4 font-slab text-label-s', className)}
        {...props}
      >
        <HighlightedMatchesAndEntities
          socialSource={socialSource?.name as SocialSource}
          text={excerpt}
        />
      </div>
    )
  },
)
ArticleItemExcerpt.displayName = 'ArticleItem.Excerpt'

export { ArticleItemExcerpt, type ArticleItemExcerptProps }
