import {
  Article,
  Loader,
  cn,
  getArticleId,
  useEventListener,
} from '@opoint/infomedia-storybook'
import { useRef, useState } from 'react'

import debounce from 'lodash-es/debounce'

import * as Tabs from './Tabs'
import { TabType } from './Tabs'
import { ArticleItem } from './ArticleItem'

const FETCH_MORE_SCROLL_THRESHOLD = 1000

export type DocumentWithBadgeType = Article & { badgeType: TabType }

type ArticlesObject = {
  count: number
  loading: boolean
  data: DocumentWithBadgeType[]
  fetchMore: () => Promise<void>
  error: string
}

type ListProps = {
  activeArticleId: string | null
  onActiveArticleChange: (article: Article) => void
} & ArticlesObject

const List = ({
  data,
  loading,
  fetchMore,
  onActiveArticleChange,
  activeArticleId,
  error,
}: ListProps) => {
  const scrollElement = useRef<HTMLDivElement>(null)

  const handleFetchMoreArticles = debounce(fetchMore, 1000)

  const handleScroll = () => {
    if (!scrollElement.current || loading || error) {
      return
    }

    const { scrollHeight, scrollTop, clientHeight } = scrollElement.current

    // scrollHeight - scrollTop - clientHeight gives you distance from the bottom of the container to your scroll position
    if (scrollHeight - scrollTop - clientHeight > FETCH_MORE_SCROLL_THRESHOLD) {
      return
    }

    void handleFetchMoreArticles()
  }

  useEventListener('scroll', handleScroll, scrollElement)

  if (error) {
    return (
      <div className="my-5 flex justify-center">
        <p>Error: {error}</p>
      </div>
    )
  }

  if (data.length === 0) {
    return (
      <div className="my-5 flex justify-center">
        <p>No articles found</p>
      </div>
    )
  }

  return (
    <div className={cn('h-full overflow-auto')} ref={scrollElement}>
      {data.map((article) => {
        return (
          <ArticleItem
            key={getArticleId(article)}
            article={article}
            onClick={onActiveArticleChange}
            badgeType={article.badgeType}
            activeArticleId={activeArticleId}
          />
        )
      })}
      {loading && (
        <div className="flex justify-center p-2">
          <Loader />
        </div>
      )}
    </div>
  )
}

type Props = {
  resultsArticles: ArticlesObject
  addedArticles: ArticlesObject
  removedArticles: ArticlesObject
  activeArticleId: string | null
  onActiveArticleChange: (article: Article) => void
  isCompareEnabled: boolean
}

const ArticleLists = ({
  resultsArticles,
  addedArticles,
  removedArticles,
  activeArticleId,
  onActiveArticleChange,
  isCompareEnabled,
}: Props) => {
  const [resultType, setResultType] = useState<TabType>('results')
  const tabs: { type: TabType; count: number; hasUnderline: boolean }[] =
    isCompareEnabled
      ? [
          { type: 'results', count: resultsArticles.count, hasUnderline: true },
          { type: 'added', count: addedArticles.count, hasUnderline: true },
          { type: 'removed', count: removedArticles.count, hasUnderline: true },
        ]
      : [{ type: 'results', count: resultsArticles.count, hasUnderline: false }]

  const activeList = {
    results: resultsArticles,
    added: addedArticles,
    removed: removedArticles,
  }[resultType]

  return (
    <>
      <Tabs.Wrapper>
        {tabs.map(({ type, count, hasUnderline }) => (
          <Tabs.Item
            activeType={resultType}
            count={count}
            hasUnderline={hasUnderline}
            key={type}
            onActiveTypeChange={setResultType}
            type={type}
          />
        ))}
      </Tabs.Wrapper>
      <List
        {...activeList}
        activeArticleId={activeArticleId}
        key={resultType}
        onActiveArticleChange={onActiveArticleChange}
      />
    </>
  )
}

export default ArticleLists
