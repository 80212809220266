import {
  ListingStyle,
  StorybookProvider as Provider,
} from '@opoint/infomedia-storybook'
import { ReactNode } from 'react'

export const StorybookProvider = ({ children }: { children: ReactNode }) => {
  return (
    <Provider
      value={{
        imageProxyUrl: '',
        locale: 'en-GB',
        dateVariant: 'absolute',
        articleListingStyle: ListingStyle.SEARCH_ENGINE,
        editedArticles: [],
      }}
    >
      {children}
    </Provider>
  )
}
